<template>

  <div class="mt-1" style="background-color: var(--white); overflow-x:auto;">
    
    <section id="fracture" style="width: 590px; margin: auto; ">
    <div class="col-12 print-header m-0 p-0">
          <img src="" style="width: 100%;">
      </div>
    <div class="body" v-for="(odds, i) in oddsFixture" :key="getKey(i)">
      
    
      <div class="table-responsive">
        <!--<button style="margin-top: 2em;" class="btn btn-primary text-white" @click="print">Print</button>-->
        <table class="table">
          <thead class=" text-primary text-center">
            <tr>
              <th rowspan="2">TIME</th>
              <th rowspan="2">GAME ID</th>
              <th rowspan="2">TEAMS</th>
              <th :colspan="outcomes.length" v-for="h in outcome" :key="getKey(h)">{{h.market_name}}</th>
            </tr>
            <tr>
              <th v-for=" i in outcomes" :key="getKey(i)">{{i}}</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="f in odds" :key="getKey(f)">
            <td>
              {{f.time}}
            </td>
            <td>
              {{f.game_id}}
            </td>
            <td>
              {{f.name}}
            </td>
            <td v-for="o in f.odds" :key="getKey(o)">
              {{o}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 print-header d-none">
          <img src="/img/header/print_footer@3x.png" style="width: 100%;">
      </div>
    </div>
    </section>

    <div class="downloader p-2" style="position: fixed; bottom:0; width: 100%; background-color: transparent; max-width: 568px;">
        <button @click="download" :disabled="load" class="btn btn-success form-control text-light " style="background-color: #FAE303;"> {{ load ? 'Please wait...' : 'Download Now' }}  </button>
    </div>
  </div>
</template>


<script>

import axios from "@/services/api";
export default {
  name: 'PrintGame',
  components: {
   
  },
  data: function () {
    return {
      start_date: this.$route.params.start_date,
      end_date: this.$route.params.end_date,
      market: this.$route.params.market,
      match_count: this.$route.params.match_count,
      sport: this.$route.params.sport,
      oddsFixture: [],
      fixtures: [],
      headers: [],
      outcomes: [],
      load: false
    }
  },
  methods: {
    getKey: function(index) {
      return Math.random().toString(20).replace('0.', 'random-id-'+index + '-');
    },
    chunk: (arr, size) =>
      arr
        .reduce((acc, _, i) =>
          (i % size)
            ? acc
            : [...acc, arr.slice(i, i + size)]
  , []),
    download: function(){
      this.load = true;
      this.printFacture();
    },
    getBook: function () {
      var vm = this;
      var path = process.env.VUE_APP_URL_BOOKLET.replace("{sport_id}",this.sport);
      axios.post(path,JSON.stringify({
        start_date: this.start_date,
        end_date: this.end_date,
        market: this.market
      }))
              .then(res => {
                vm.busy = false;
                vm.fixtures = res.data.message.fixtures;
                vm.headers = res.data.message.headers;
                let fix = this.chunk(vm.fixtures, vm.fixtures.length);
                //console.log("OBJ-->", JSON.stringify(fix));
                var outcomes = [];
                vm.jQuery.each(vm.headers,function(k,v){
                  vm.jQuery.each(v.outcomes,function(kk,vv){
                    outcomes.push(vv);
                  })
                });
                this.outcomes = outcomes;
                vm.jQuery.each(vm.fixtures,function(k,v){
                  var odds = [];
                  vm.jQuery.each(v.markets,function(kk,vv){
                    vm.jQuery.each(vv.Odds,function(kkk,vvv){
                      odds.push(vvv);
                    })
                  })
                  v.odds = odds;
                  vm.fixtures[k] = v;
                });
                //this.odds = vm.fixtures;
                this.oddsFixture = fix;
                 
              })
              .catch(error => {
                console.log(error)
              })
    },
    print: function () {
      return window.print();
    }
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage","print-game");
    this.getBook()
    
  },
  computed: {
    outcome: function () {
      return this.headers;
    },
    fixture: function () {
      return this.fixture();
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
  }
}
</script>
<style scoped>
  .navbar{
    display: none !important;
  }
  table {
    text-indent: initial;
    white-space: normal;
    line-height: normal;
    font-weight: normal;
    font-size: medium;
    font-style: normal;
    text-align: start;
    border-spacing: 2px;
    font-variant: normal;
  }
  .table-responsive table {
    width: 100%;
    box-sizing: border-box;
    border-left: #ccc solid 1px;
    border-right: #ccc solid 1px;
  }
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  tr{
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  .table-responsive table tr td:nth-child(1), .table-responsive table tr td:nth-child(2), .table-responsive table tr td:nth-child(3){
    font-weight: 400;
  }
  .table-responsive table tr td:nth-child(1), .table-responsive table tr td:nth-child(2), .table-responsive table tr td:nth-child(3){
    border-right: #bbb solid 1px;
  }
  .table-responsive table tr:nth-of-type(odd) td {
    background: #eee;
  }
  .table-responsive table tr th {
    display: table-cell;
    font-size: 2.5mm;
    color: #fff;
    background: #555;
    padding: 1mm 2.0mm;
    border-right: #ccc solid 1px;
    border-bottom: #ccc solid 1px;
  }
  .table-responsive table tr th:last-child {
    border-right: none;
  }
  .table-responsive table tr td {
    background: #fff;
    color: #000;
    padding: .8mm 1.5mm;
    font-size: 2.54mm;
    text-align: center;
    border-bottom: #ccc solid 1px;
    white-space: nowrap;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 32mm;
  }
</style>